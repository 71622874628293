import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      redirect: '/questionnaire'
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: () => import(/* webpackChunkName: "home" */ '@/views/questionnaire/Questionnaire.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/questionnaireSucess',
      name: 'questionnaireSucess',
      component: () => import(/* webpackChunkName: "home" */ '@/views/questionnaire/QuestionnaireSucess.vue'),
      meta: {
        index: 1
      }
    },
  ]
})

export default router